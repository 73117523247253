



.soundcloud {
  padding: 50px;
}

/* Responsive adjustments */
@media (max-width: 700px) {
  .musicLink {
    width: 60px; /* Adjust the width for smaller screens */
  }

  .musicLink img {
    max-width: 50px; /* Adjust the max-width for smaller screens */
  }
}
