.socialsContainer {
  background-color: #040404;
  padding-top: 50px;
}

.socialsContent {
  padding: 5px;
  background-color: #040404;
}

.socialsContent ul {
  padding: 5px;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.socialsLink {
  width: 50px; /* Reduce the width of each social link */
}

.socialsLink img {
  width: 70%;
  max-width: 70px; /* Reduce the maximum width of the social link images */  height: auto;
  transition: transform 0.3s;
}

.socialsLink img:hover {
  transform: scale(1.1);
}

@media (max-width: 700px) {
  .socialsLink {
    width: 50px; /* Further reduce width for smaller screens */
  }

  .socialsLink img {
    max-width: 40px; /* Further reduce image size for smaller screens */
  }
}
