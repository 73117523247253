.video-container {
  position: relative;
  display: inline-block;
  box-sizing: border-box; 
  overflow: hidden; 
  padding: 1%;
}

.video-container video {
  width: 100%; 
  height: auto; 
  object-fit: contain; 
}

.video-logo-container {
  position: absolute;
  width: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0; /* Hidden logo at start */
  animation: zoomIn 1s forwards;
  z-index: 1;
}

@keyframes zoomIn {
  0% {
    transform: translate(-50%, -50%) scale(0.5); /* Start smaller size */
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1); /* End normal size */
    opacity: 1;
  }
}

.scroll-down-arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  animation: bounce 1s infinite alternate;
  z-index: 2;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

.scroll-down-arrow span {
  display: block;
  width: 24px;
  height: 24px;
  border-bottom: 2px solid #FAFA33;
  border-right: 2px solid #FAFA33;
  transform: rotate(45deg);
  margin: 0 auto;
}

@keyframes bounce {
  0% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, 5px);
  }
}

.about {
  padding: 20px;
  margin-top: 50px;
  color: #f8f8f0;
  padding: 1%;
}

.photo-credit {
  color: #f8f8f0;
  padding: 5px; 
}

.photo-credit a {
  color: #f8f8f0; 
  text-decoration: underline; 
}

.photo-credit a:hover {
  color: #555;

}

@media (max-width: 768px) {
  .video-container {
    height: 100vw;
    padding: 2%;
  }

  .video-container video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

}
