/* App.css */
/* Reset default browser styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  overflow-x: hidden;

}

.tabcontent {
  background-color: #040404;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
