.image-slide-in {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 300px;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;

    padding: 1%;
    overflow: hidden; 
  }
  
  .image-slide-in img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  
  .image-slide-in.slide-in {
    opacity: 1;
    transform: translateY(0);
  }
  

  